var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"width":"100vw"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.newForm}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('NEW_FORM'))+" ")],1)],1),_c('v-row',{staticClass:"pa-5",staticStyle:{"width":"100%"}},[_c('v-data-table',{staticStyle:{"width":"100vw"},attrs:{"items":_vm.forms,"headers":_vm.formDTHeaders,"show-select":""},scopedSlots:_vm._u([{key:"item.entity",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(item.entity)))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.active ? "green" : "red"}},[_vm._v(_vm._s(item.active ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.formatDate(item.createdAt)) + " - " + (_vm.formatTime(item.createdAt)))))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.openFormDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('EDIT')))])])],1)]}}]),model:{value:(_vm.selectedForms),callback:function ($$v) {_vm.selectedForms=$$v},expression:"selectedForms"}})],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.newFormDialog),callback:function ($$v) {_vm.newFormDialog=$$v},expression:"newFormDialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"px-3 mb-5",attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(_vm.$t('SELECT_ENTITY')))]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":_vm.$t("ENTITY"),"items":_vm.entities,"item-text":"description","item-value":"value","return-object":"","hide-details":"auto"},model:{value:(_vm.selectedEntity),callback:function ($$v) {_vm.selectedEntity=$$v},expression:"selectedEntity"}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.openFormDialog(null)}}},[_vm._v(" "+_vm._s(_vm.$t('CREATE'))+" ")])],1)],1)],1),(_vm.selectedForm)?_c('v-dialog',{staticStyle:{"overflow-x":"hidden !important"},attrs:{"fullscreen":"","persistent":""},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}},[_c('v-toolbar',{staticClass:"pt-1 px-3",attrs:{"color":"primary","dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeFormDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('FORM_ENTITY', { entity: _vm.$t(_vm.selectedForm.entity) })))])],1),_c('v-card',[_c('CustomerDynamicFormBuilder',{attrs:{"dynamic-form":_vm.selectedForm,"entity":_vm.selectedForm.entity},on:{"form-updated":_vm.getData}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }