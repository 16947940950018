<template>
  <v-container fluid>
    <v-row no-gutters>
      <div class='text-h5'> {{$t('CONFIGURATIONS')}} </div>
    </v-row>

    <v-divider class='my-3' />

    <v-row no-gutters>
      <v-card tile flat color='white'>
        <v-tabs v-model='selectedTab' background-color='transparent'>
          <v-tab>{{ $t('COLLECT_FLOW') }}</v-tab>
          <v-tab>{{ $t('CAPTURE_MODULE') }}</v-tab>
          <v-tab>{{ $t('THEME') }}</v-tab>
          <v-tab>{{ $t('DYNAMIC_FORMS') }}</v-tab>
        </v-tabs>
        <v-container fluid class='pa-0'>
          <v-tabs-items v-model='selectedTab' class='transparent'>
            <v-tab-item>
              <CustomerCollectFlow />
            </v-tab-item>
            <v-tab-item>
              <CustomerCaptureModule />
            </v-tab-item>
            <v-tab-item>
              <CustomerTheme />
            </v-tab-item>
            <v-tab-item>
              <CustomerDynamicForms />
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import CustomerTheme from '@/components/Customers/CustomerTheme';
  import CustomerDynamicForms from '@/components/Customers/CustomerDynamicForms.vue';
  import CustomerCollectFlow from '@/components/Customers/CustomerCollectFlow';
  import CustomerCaptureModule from '@/components/Customers/CustomerCaptureModule';

  export default {
    name: 'CustomerSettings',
    components: {
      CustomerTheme,
      CustomerDynamicForms,
      CustomerCollectFlow,
      CustomerCaptureModule,
    },
    data: function () {
      return {
        selectedTab: 0,
      };
    },
  };
</script>

<style scoped>
</style>
