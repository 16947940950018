<template>
  <v-container fill-height fluid>
    <v-row no-gutters align='center' justify='center'>
      <draggable :list='collectItems' class='list-group'>
        <div v-for='(element, i) in collectItems' :key='element.code' class='list-group-item my-3'>
          <v-col class='d-flex' style='align-items: center'>
            <div class='item-order mr-3'>{{ i + 1 }}</div>
            {{ $t(element.name) }}
          </v-col>
        </div>
      </draggable>
    </v-row>
    <v-card-actions class='wd-100'>
      <v-col>
        <v-btn depressed block color='secondary' outlined @click='getCollectFlow()'>
          {{ $t('CANCEL') }}
        </v-btn>
      </v-col>
      <v-col>
        <v-btn depressed block color='secondary' @click='saveCollectFlow()'>
          {{ $t('SAVE') }}
        </v-btn>
      </v-col>
    </v-card-actions>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { mapGetters } from 'vuex';
  import draggable from 'vuedraggable';

  export default {
    name: 'CustomerCollectFlow',
    components: {
      draggable,
    },
    data: function () {
      return {
        collectItems: [
          {
            code: 'Bio',
            name: 'BIOGRAPHICAL_DATA',
          },
          {
            code: 'Face',
            name: 'FACE_PHOTO',
          },
          {
            code: 'Finger',
            name: 'FINGERPRINT',
          },
          {
            code: 'Infant',
            name: 'INFANT_FINGERPRINT',
          },
          {
            code: 'Signature',
            name: 'SIGNATURE',
          },
          {
            code: 'Mugshot',
            name: 'MUGSHOT',
          },
          {
            code: 'Scanner',
            name: 'SCANNER',
          },
          {
            code: 'Palm',
            name: 'PALM',
          },
          {
            code: 'InfantAuthLogs',
            name: 'AUTHENTICATIONS',
          },
          {
            code: 'Summary',
            name: 'SUMMARY',
          },
        ],
      };
    },
    computed: {
      ...mapGetters('auth', ['getUser']),
    },
    mounted () {
      this.getCollectFlow();
    },
    methods: {
      getCollectFlow: async function () {
        const { data } = await axios({
          url: `/customer/${this.getUser.account.customerId}/configuration/collect`,
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });
        if (data?.collect?.length) {
          this.collectItems = data.collect;
        }
      },
      saveCollectFlow: function () {
        for (let i = 0; i < this.collectItems.length; i++) {
          this.collectItems[i].order = i + 1;
        }
        Swal.fire({
          title: 'Aviso',
          text: 'Deseja salvar o fluxo de coleta? Esta alteração irá refletir nas estações de captura.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Salvar',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.value) {
            await axios({
              url: `/customer/${this.getUser.account.customerId}/configuration/collect`,
              baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
              method: 'POST',
              responseType: 'json',
              withCredentials: true,
              data: {
                data: this.collectItems,
              },
            });
          }
        });
      },
    },
  };
</script>

<style scoped>
  .list-group-item {
    padding: 1px;
    background: var(--v-primary-base);
    border-radius: 15px;
    color: white;
    font-weight: 600;
  }

  .item-order {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--v-secondary-base);
  }

</style>
